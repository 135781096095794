<template>
  <div class="warp">
    <div class="titie">
      <img class="titie-img" src="../../assets/img/logo@3x.png" alt="" />
    </div>
    <div class="login">
      <van-form @submit="onSubmit">
        <van-field
          class="fieldbtn"
          v-model="logindata.phone"
          maxlength="11"
          name="手机号"
          :left-icon="require('../../assets/img/sj.png')"
          placeholder="请输入您的手机号"
        >
        </van-field>
        <van-field
          class="fieldbtn"
          v-model="logindata.password"
          maxlength="20"
          type="password"
          name="密码"
          :left-icon="require('../../assets/img/mm.png')"
          placeholder="请输入密码"
        />
        <div class="password-box">
          <van-checkbox v-model="checked" shape="square" icon-size="14px"
            >记住密码</van-checkbox
          >
          <div style="color: #0090ff" @click="forgetpassword">忘记密码</div>
        </div>
        <div style="margin: 16px">
          <van-button
            style="border-radius: 8px"
            color="linear-gradient(to right, #BCA675, #A78A48)"
            block
            type="primary"
            size="large"
            @click="signin(false)"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { hex_md5 } from "../../utils/md5";
import { login, getGzhCode } from "../../api/login/login";
import {
  setToken,
  setUserId,
  setDate,
  getDate,
} from "../../utils/LocalStorage";
export default {
  data() {
    return {
      logindata: {
        phone: "",
        password: "",
        type: "1",
      },
      lodlogindata: {},
      checked: false,
      loginbool: false,
    };
  },
  methods: {
    // 忘记密码
    forgetpassword() {
      this.$router.push({
        path: "/forgetPassword",
        params: {},
      });
    },
    // 登录
    signin(bool) {
      setTimeout(() => {
        // 判断信息是否完整
        if (this.loginbool) {
          // 避免 变量引用污染
          this.lodlogindata = { ...this.logindata };
          // 使用md5 加密密码
          this.lodlogindata.password = hex_md5(this.lodlogindata.password);
          // 登录 接口
          login(this.lodlogindata).then((res) => {
            if (res.data.code == 0) {
              // 存储 token、id和用户信息，后面的接口头部需要token、id
              setToken(res.data.data.token);
              setUserId(res.data.data.id);
              setDate(JSON.stringify(res.data.data));
              // 判断 自动登录是否过期，过期才存储
              if (!bool) {
                let obj = {
                  data: new Date().getTime() + 300000,
                  phone: this.logindata.phone,
                  pass: this.logindata.password,
                };
                localStorage.setItem("datetime", JSON.stringify(obj));
              }
              // true为记住密码，存储账号密码，false就删除
              if (this.checked) {
                localStorage.setItem("phone", this.logindata.phone);
                localStorage.setItem("pass", this.logindata.password);
              } else {
                localStorage.removeItem("phone");
                localStorage.removeItem("pass");
              }
              // 获取code
              this.logincode();
            } else {
              this.$toast(res.data.msg);
            }
          });
        }
      });
    },
    // 判断信息是否填写完整
    onSubmit(values) {
      if (values.密码 == "" || values.手机号 == "") {
        this.$toast("请填写完整");
        this.loginbool = false;
      } else {
        this.loginbool = true;
      }
    },
    // 获取code
    logincode() {
      let url = JSON.parse(getDate()).url;
      let code = this.getUrlCode().code;
      // // 如果没有code 去获取code
      if (this.code == null) {
        window.location.href = url;
      } else {
        // 获取到code后的逻辑
        console.log("code", this.code);
        // code 发送到后台
        getGzhCode({ code: this.code }).then((res) => {
          console.log(res);
        });
      }
    },
    //截取code
    getUrlCode() {
      // 截取url中的code方法
      var url = location.search;
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
        }
      }
      return theRequest;
    },
  },
  created() {
    // 判断本地是否存在账号密码,有就是用户记住过账号密码，需要自动填写账号密码
    if (localStorage.getItem("phone") && localStorage.getItem("pass")) {
      this.logindata.phone = localStorage.getItem("phone");
      this.logindata.password = localStorage.getItem("pass");
      this.checked = true;
    }
  },
  mounted() {
    // 判断本地是否存在自动登录信息
    if (localStorage.getItem("datetime")) {
      let datetime = JSON.parse(localStorage.getItem("datetime"));
      // 判断自动登录信息的期限是否过期，没过期则自动登录
      if (datetime.data > new Date().getTime()) {
        this.logindata.phone = datetime.phone;
        this.logindata.password = datetime.pass;
        this.loginbool = true;
        this.signin(true);
      }
    }
  },
};
</script>

<style scoped>
.warp {
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/img/bj.png");
}
.titie {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.titie-img {
  width: 87px;
  height: 91px;
  border-radius: 50%;
}
.fieldbtn {
  margin-top: 13px;
  border-radius: 22px;
  border: 1px solid #dededc;
  background-color: rgba(255, 255, 255, 0.1);
}
.login {
  width: 90%;
  margin: auto;
}
.password-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 56px;
  margin-top: 20px;
  padding: 0 20px;
}
.sign {
  display: flex;
  justify-content: center;
}
.sign-btn {
  width: 100%;
}
</style>
